import React from 'react';
import { useQuery } from '../hooks/useQuery';

function Artists() {
  const data = useQuery();
  console.log(data);
  const musicArtists = data.musicArtists.nodes;

  return (
    <div className="music-container columns is-centered mt-4">
      {musicArtists.map((musicArtist, index) => (
        <div className="column has-text-centered m-3" key={index}>
          <img
            src={musicArtist.frontmatter.img}
            alt={musicArtist.frontmatter.artist}
          />
          <h4 className="mt-2">{musicArtist.frontmatter.artist}</h4>
        </div>
      ))}
    </div>
  );
}

export default Artists;
