import { useStaticQuery, graphql } from 'gatsby';

export const useQuery = () => {
  const props = useStaticQuery(graphql`
    query MyQuery {
      musicArtists: allMdx(
        filter: { frontmatter: { category: { eq: "musicArtists" } } }
      ) {
        nodes {
          frontmatter {
            category
            artist
            img
            location
          }
          body
        }
      }
      mainEvents: allMdx(
        filter: { frontmatter: { subcategory: { eq: "main" } } }
        sort: { fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            category
            title
            date
            date2
            start_time
            venue_address
            venue_name
            slug
          }
          body
        }
      }
      events: allMdx(
        filter: { frontmatter: { category: { eq: "event" } } }
        sort: { fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            category
            title
            date
            date2
            start_time
            venue_address
            venue_name
            slug
            map_link
          }
          body
        }
      }
    }
  `);
  return props;
};
