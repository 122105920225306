import MainEvents from "../../../../src/components/MainFeatured.js";
import ESUB from "@bgea/bgforms-templates-international-crusades/dist/templates/ESUB";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import Artists from "../../../../src/components/Artists.js";
import * as React from 'react';
export default {
  MainEvents,
  ESUB,
  BsFacebook,
  BsInstagram,
  Artists,
  React
};